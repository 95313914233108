import RoutePage from "./components/pages/Routes";

function App() {
  return (
    <div className="root">
        <RoutePage/>
    </div>
  );
}

export default App;

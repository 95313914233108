import React, { useState } from "react";
import "./Carousel.css";
import slider1 from "../../images/1.png";
import slider2 from "../../images/2.png";
import slider3 from "../../images/3.png";
import slider4 from "../../images/4.png";
import slider5 from "../../images/5.png";
const Carousel = () => {
  const [checked, setChecked] = useState(1);

  return (
    <section id="slider">
      {[...Array(5)].map((_, idx) => (
        <input
          type="radio"
          name="slider"
          id={`s${idx + 1}`}
          onClick={() => setChecked(idx + 1)}
          checked={idx + 1 === checked}
        />
      ))}
      <label for="s1" id="slide1">
        <img src={slider1} id="slide1" />
      </label>
      <label for="s2" id="slide2">
        <img src={slider2} id="slide2" />
      </label>
      <label for="s3" id="slide3">
        <img src={slider3} id="slide3" />
      </label>
      <label for="s4" id="slide4">
        <img src={slider4} id="slide4" />
      </label>
      <label for="s5" id="slide5">
        <img src={slider5} id="slide5" />
      </label>
    </section>
  );
};

export default Carousel;
